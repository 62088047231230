<template>
  <div class="home">
    <HeadTop />
    studyRoom
  </div>
</template>

<script>
// @ is an alias to /src
import HeadTop from '@/components/HeadTop.vue'
export default {
  name: 'studyRoom',
  components: {
    HeadTop
  }
}
</script>
